.auth--title--container {
    display: flex;
    flex-direction: column;
}

.auth--title {
    position: center;
    text-align: center;
    font-size: 40px;
    margin-left: 0.7em;
    letter-spacing: 0.7em;
    font-weight: 300;
}

.auth--button--container {
    display: flex;
    margin-top: 20vh;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

.auth--container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth--button {
    font-family: 'Roboto';
    font-size: large;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 15px 10px;
    min-width: 300px;
    font-size: 24px;
    border-radius: 10px;
    letter-spacing: 8px;
    font-weight: 300;
}

.auth--button:hover {
    transition: 0.2s;
    background-color: white;
    color: black;
}

.auth--button:active {
    background-color: grey;
}

.auth--reconnect {
    font-family: 'Roboto';
    font-size: large;
    background-color: white;
    color: black;
    border: 2px solid black;
    padding: 15px 10px;
    min-width: 300px;
    font-size: 24px;
    border-radius: 10px;
    letter-spacing: 8px;
    font-weight: 300;
}

.auth--reconnect:hover {
    transition: 0.2s;
    color: white;
    background-color: transparent;
    border: 2px solid white;
}

.auth--reconnect:active {
    background-color: grey;
}

.auth--disclaimer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
}

.auth--disclaimer a {
    color: white;
}

.auth--header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth--header p {
    font-weight: 200;
    letter-spacing: 4px;
}