/* Imports */
@import url("https://fonts.googleapis.com/css2?family=Inter");
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900');


/* Top-level containers */
body {
    display: flex;
    margin: 0;
    font-family: 'Roboto', Arial, Helvetica;
    background: linear-gradient(#07051d 57%, #0e038d);
    color: white;
    font-size: 18px;
    height: 100%;
    justify-content: center;
}

/* App.js */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.company--footer {
    font-size: smaller;
    padding: 5px;
    text-align: center;
}

.page--container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.instagram-div {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header image */
.header--image--container {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.header--image--container>img {
    width: 175px;
    height: 55px;
}

/* Header & tabs */
header {
    background-color: transparent;
    margin-bottom: 20px;
}

.back--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
}

.back--icon:hover {
    color: lightgrey;
}

.back--icon:active {
    color: grey;
}

.header--route {
    width: inherit;
    margin: 10px 0px;
}


.Welcome-message-container {
    color: white;
    font-family: 'Roboto';
    letter-spacing: 8px;
    text-align: center;
    margin: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Welcome-message-container * {
    margin: 0px;
}

.welcome--teamname {
    width: 95vw;
    overflow-wrap: break-word;
}

.Header-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 10px;
}

.header--container {
    display: flex;
    flex-direction: column;
}

.header--container>img {
    width: 80px;
    align-self: center;
}

.header--route--tracker {
    position: absolute;
    width: 420px;
    height: 19px;
    margin-top: 20px;
    left: 20px;
}

.header--route--container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.route--tracker--inactive--icons {
    display: flex;
    gap: 7vw;
    width: 95vw;
    background-image: url("../resources/routeTrackerImg.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.route--tracker--active--circle {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 50%;
}

.route--tracker--inactive--circle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    row-gap: 50px;
    column-gap: 50px;
}

.route--tracker--inactive--circle * {
    gap: 30px;
}

.back--button--container {
    margin-top: 25px;
}

/* Welcome page */
.welcome--container {
    width: 90vw;
    max-width: 450px;
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.welcome--bottom--code {
    cursor: pointer;
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 20px;
    background-color: transparent;
    font-family: 'Roboto';
    color: white;
    text-align: center;
    letter-spacing: 3px;
}

.welcome--bottom--code * {
    margin: 0;
}

.welcome--bottom--code>h1 {
    font-size: 24px;
}

.welcome--bottom--code>p {
    font-size: 32px;
}

.welcome--bottom--code--icon {
    font-size: 32px;
}

.welcome--bottom--copied {
    cursor: pointer;
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 20px;
    background-color: white;
    font-family: 'Roboto';
    color: black;
    letter-spacing: 3px;
}

.welcome--tick {
    font-size: 40px;
}

.welcome--title {
    margin-top: 30px;
}

.welcome--title>h1 {
    margin: 0;
}

.welcome--reload:hover {
    background-color: lightgrey;
}

.welcome--reload:active {
    background-color: grey;
}

.welcome--teamname {
    font-weight: normal;
}

.welcome--admin--button {
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 20px;
    background-color: transparent;
    font-family: 'Roboto';
    color: white;
    text-align: center;
    letter-spacing: 3px;
    font-size: 25px;
}

.welcome--admin--button:hover {
    background-color: lightgray;
}

.welcome--admin--button:active {
    background-color: gray;
}

/* Scoresheet */
.scoresheet--container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-width: 6px;
    border-color: white;
    padding: 10px;
    text-align: left;
    color: white;
    border: 5px solid white;
    border-radius: 15px;
    margin-bottom: 200px;
    margin-top: 50px;
}

.scoresheet--table td {
    overflow: scroll;
    background-color: transparent;
    color: white;
    text-align: center;
    font-family: 'Roboto';
    overflow: hidden;
    border-radius: 10px;
    border-width: 5px;
    border-color: white;
    padding-left: 10px;
    margin-left: 10px;
    border-spacing: 0px 20px;
    word-break: break-all;
}

.scoresheet--table--header {
    background-color: transparent;
    color: white;
    text-align: center;
}

.scoresheet--table--header>th {
    padding: 5px;
}

.scoresheet--table--input {
    width: 50px;
    font-family: 'Roboto';
    border: none;
    font-size: 18px;
    background-color: transparent;
    color: white;
}

.scoresheet--submit {
    font-family: 'Roboto';
    font-size: large;
    border: none;
    margin: 10px;
    color: black;
}

.scoresheet--penalty--container {
    display: flex;
    gap: 10px;
    margin: 0px 10px;
}

.scoresheet--penalty--container>select {
    width: 60vw;
}

.scoresheet--penalty--container>button {
    width: 25vw;
}

.scoresheet--penalty--title {
    margin: 0;
    margin-top: 20px;
}

.scoresheet--penalty--reload {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.scoresheet--penalty--reload>button {
    width: 200px;
    font-size: 24px;
    border: 2px solid white;
    color: white;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
}

.leaderboard--button--headrow {
    margin-right: 50px;
}


/* Leaderboard */
.leaderboard--container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    color: white;
    border: 4px solid white;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 200px;
}

.leaderboard--title {
    display: flex;
    margin: 0;
    text-align: left;
    font-size: 32px;
}

.leaderboard--title h1 {
    margin: 0;
    font-size: 32px;
}

.leaderboard--title hr {
    width: 35%;
    height: 35%;
    border: none;
    border-bottom: 2px solid white;
}

.leaderboard--table {
    width: 80vw;
    margin: 20px;
    border-radius: 10px;
    padding: 10px 50px;
    background-color: transparent;
    border-width: 5px;
    border-color: white;
    margin: 0;
}

.leaderboard--table td {
    overflow: scroll;
    background-color: transparent;
    color: white;
    text-align: center;
    font-family: 'Roboto';
    overflow: hidden;
    border-radius: 10px;
    border-width: 5px;
    border-color: white;
    padding-left: 10px;
    margin-left: 10px;
    border-spacing: 0px 20px;

}

.leaderboard--table--header {
    font-size: large;
    background-color: transparent;
    color: white;
    text-align: center;
    font-family: 'Roboto';
    margin-right: 50px;
}

.leaderboard--table--header>* {
    height: 2px;
    font-family: 'Roboto';
}

.leaderboard--reload {
    margin: 0px 20px;
    padding: 10px;
    border: none;
}

/* Footer */
footer {
    margin-top: auto;
    bottom: 0;
    display: flex;
    width: 95vw;
    justify-content: space-between;
    align-items: flex-end;
    background-color: transparent;
}

footer * {
    margin: 10px;
}

.footer--top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.footer--signout {
    margin-top: 100px;
    height: 25px;
    color: black;
    background-color: white;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 10px 5px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    letter-spacing: 0.1em;
}

.footer--signout:hover {
    background-color: lightgrey;
}

.footer--signout:active {
    background-color: grey;
}

.footer--code {
    font-size: large;
}

.footer--container {
    display: flex;
    flex-direction: column;
}

.footer--legal {
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 400px;
    width: 70%;
    justify-content: space-between;
    text-align: center;
}

.footer--legal>p {
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.footer--legal>p:visited {
    color: white;
}

.footer--legal>p:hover {
    color: lightgrey;
}

.footer--legal>p:active {
    color: grey;
}

.toggle--container {
    display: flex;
    flex-direction: column;
}

.challenges--toggle {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: baseline;
}

.challenges--toggle>input {
    transform: scale(1.5);
}

.footer--non-alc {
    display: flex;
}

/* Admin sign in */
.admin--signin--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin--signin--form {
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
    justify-content: center;
    width: 300px;
}

.admin--signin--form>input {
    height: 32px;
    font-size: 18px;
}

/* Route page */
.route--holes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0px;
}

.route--hole {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    word-break: break-all;
}

.route--hole>img {
    width: 50vw;
    height: 125px;
    object-fit: cover;
}

.route--hole--text--active {
    font-size: 18px;
    position: absolute;
    top: 25%;
    bottom: 15%;
    left: 20%;
    right: 20%;
    background-color: #07051d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 5px;
}

.route--hole--text--active * {
    margin: 0;
    margin-bottom: 2.5px;
}

.route--hole--text--inactive {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #120846;
    left: 20%;
    right: 20%;
    top: 10%;
    bottom: 5%;
}

.route--hole--text--inactive * {
    margin: 0;
    font-size: smaller;
}

.route--hole--whtext {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 80%;
    bottom: 75%;
    font-size: 16px;
    background-color: red;
    font-weight: bold;
    margin: 0;
    color: white;
}

.route--hole--name--inactive {
    font-weight: bold;
    font-size: 18px;
}

.route--hole--whtext * {
    margin: 5px;
}

.route--hole--image--inactive {
    filter: grayscale(1.0) contrast(0.2) brightness(1.7);
}

.route--reload {
    width: 100px;
    padding: 20px;
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: bold;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    font-size: 50px;
}

.route--reload:hover {
    background-color: lightgrey;
}

.route--reload:active {
    background-color: grey;
}

/* Admin page */
.admin--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    text-align: center;
    color: white;
}

.admin--pub-buttons {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
}

.admin--pub-buttons>button {
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 15px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto';
    font-size: 24px;
    word-wrap: anywhere;
    word-spacing: 40px;
}

.admin--pub-buttons>button:disabled {
    color: grey;
}

.admin--current {
    margin: 0px;
}

.admin--table {
    width: 90vw;
    color: white;
    border: 2px solid white;
    border-radius: 10px;
    margin: 0;
    margin-bottom: 50px;
    margin-top: 10px;
    padding: 5px;
}

.admin--table td {
    overflow: scroll;
    background-color: transparent;
    color: white;
    text-align: center;
    font-family: 'Roboto';
    word-break: break-all;
}

.admin--table td input {
    width: 15vw;
    font-family: 'Roboto';
    font-size: 16px;
    background-color: white;
    color: black;
}

.admin--table th {
    background-color: transparent;
    color: white;
    text-align: center;
    border-bottom: 2px solid white;
}

.admin--table--teamname {
    width: 100px;
}

.admin--table--dropdown {
    width: 150px;
    font-family: 'Roboto';
    border: 2px solid white;
    font-size: 16px;
    background-color: transparent;
    color: white;
}

.admin--penalty--buttons {
    display: flex;
    flex-direction: column;
}

.admin--submit--penalties {
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto';
    font-size: 24px;
}

.admin--reload {
    width: 100px;
    padding: 20px;
    align-self: center;
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: bold;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    font-size: 50px;
}

/* Loading */
.loading-screen {
    z-index: 1;
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.5);
}

/* Off-season message */
.off-season--body {
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: center;
    text-align: center;
}

.off-season--body a {
    color: blue;
}

.welcome--route {
    cursor: pointer;
    position: relative;
    height: 25vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    font-size: 22px;
    border-radius: 20px;
    color: black;
    font-weight: bold;
    letter-spacing: 3px;
}

.welcome--route>img {
    filter: grayscale(1.0) contrast(0.2) brightness(1.7);
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    right: 0;
    object-fit: cover;
}

.welcome--bottom--buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    gap: 20px;
}

.score--button--add {
    margin-left: 10px;
}

.welcome--route--pub {
    word-break: break-all;
    position: absolute;
    left: 20px;
    top: 10px;
}

.welcome--route--pub * {
    margin: 0;
}

.welcome--route--drink {
    align-self: flex-end;
    position: absolute;
    margin-left: 20px;
    right: 15px;
    bottom: 10px;
    text-align: right;
    word-break: break-all;
}

.welcome--route--drink * {
    margin: 0;
}

.welcome--route--wh {
    position: absolute;
    right: 0;
    top: 0;
    background-color: red;
    color: white;
    border-radius: 0px 20px;
    padding: 10px;
    width: 20vw;
}

.welcome--route--wh>* {
    margin: 0;
}

.leaderboard-link-button {
    cursor: pointer;
    position: relative;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 5px solid white;
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
}

.leaderboard-link-button td {
    word-break: break-all;
}

.leaderboard-link-button * {
    margin: 0;
}

.leaderboard--button--header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.leaderboard--button--header>h1 {
    font-size: 28px;
}

.leaderboard--button--header>hr {
    border: none;
    width: 35%;
    height: 40%;
    border-bottom: 2px solid white;
}

.leaderboard--button--table {
    width: 100%;
}

.leaderboard--button--table--row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaderboard--button--table--row>h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.leaderboard--button--table--row>p {
    text-align: center;
    margin-bottom: 5px;
}

.leaderboard--button--footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.leaderboard--button--footer>p {
    margin: 0;
}

.leaderboard--button--footer>hr {
    border: none;
    border-bottom: 2px solid white;
    width: 80%;
    height: 35%;
}


.welcome--bottom--scoreboard {
    cursor: pointer;
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    border: 5px solid white;
    padding: 10px 20px;
    background-color: transparent;
    font-family: 'Roboto';
    color: white;
    text-align: center;
    letter-spacing: 3px;
}

.welcome--bottom--scoreboard * {
    margin: 0;
}

.welcome--bottom--scoreboard>h1 {
    font-size: 24px;
}

.welcome--bottom--scoreboard>h2 {
    font-size: 64px;
}

.welcome--bottom--scoreboard--add {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 5px;
}

.welcome--bottom--scoreboard--add>hr {
    border: 0;
    border-bottom: 2px solid white;
    width: 70%;
    height: 50%;
}


.leaderboard--page {
    display: flex;
    flex-direction: column;
}


.instagram-link {
    color: white;
    text-decoration: none;
}

.instagram-name {
    margin-top: 0px;
    color: white;
    font-family: 'Roboto';
}

.scoreboard-button-header {
    text-align: center;
    letter-spacing: 3px;
}

.scoreboard-player-score {
    margin-top: 5px;
    font-size: 70px;
    font-family: 'Roboto';
    font-weight: bold;
}

.scoreboard-see-more {
    position: absolute;
    right: 290px;
    bottom: -65px;
    font-size: 20px;
}

.route-button-text-container {
    display: grid;
    column-gap: 70vw;
    column-width: 100px;
    color: purple;
}

.Current-pub-on-button {
    font-family: 'Roboto';
    font-size: 20px;
    grid-column: 1;
    grid-row: 1;
}

.Current-pub-name-on-button {
    font-family: 'Roboto';
    font-size: 20px;
    grid-column: 1;
    grid-row: 2;
}

.Current-pub-drink-on-button {
    font-family: 'Roboto';
    font-size: 20px;
    grid-column: 2;
    grid-row: 3;
}

.Current-pub-drink-name-on-button {
    font-family: 'Roboto';
    font-size: 20px;
    grid-column: 2;
    grid-row: 4;
}

.auth--soon {
    text-align: center;
}

.route--edit--large {
    width: 100px;
}

.route--edit--small {
    width: 40px;
}

.route--edit--pub * {
    margin: 0;
}

.route--edit--tab {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid white;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;
    word-break: break-all;
}

.route--edit--content {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
    font-size: 24px;
    margin-top: 20px;
}

.route--edit--content>span {
    display: flex;
    gap: 20px;
}

.route--edit--content * {
    width: 100%;
}

.route--edit--content>span>p {
    font-weight: bold;
}

.edit--route--container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px;
}

.route--edit--top {
    display: flex;
    gap: 10px;
}

.route--edit--arrow {
    background-color: transparent;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.route--edit--arrow:enabled:hover {
    background-color: darkblue;
}

.route--edit--arrow:disabled {
    color: grey;
    cursor: default;
}

.alcohol--disclaimer--link {
    color: white;
}

.alcohol--disclaimer--button {
    color: black;
    background-color: white;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 10px 5px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    letter-spacing: 0.1em;
    margin-top: 25px;
}

/* terms of service page*/

.tos--header {
    text-align: center;
}

.terms--and--conditions {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.tos--accept {
    font-size: 25px;
    text-align: center;
}

/* privacy policy page  */

.pp--header {
    text-align: center;
}

.privacy--policy {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.pp-accept {
    font-size: 25px;
    text-align: center;
}

.cookie--bar {
    bottom: 0;
    position: fixed;
    margin: 0;
    display: flex;
    justify-content: space-between;
    background-color: black;
    color: white;
    width: 100%;
}

.cookie--bar>button {
    margin: 20px 10px;
    border: none;
    font-family: 'Roboto';
    font-size: 20px;
    border-radius: 10px;
}

.cookie--bar>span {
    margin: 10px;
}

.cookie--bar>span * {
    margin: 0;
}

.cookies--link {
    text-decoration: underline;
    cursor: pointer;
}

.cookies--page {
    margin: 20px;
}

.cookies--page a {
    color: white;
}

.cookies--table th {
    background-color: lightslategrey;
    color: black;
}

.cookies--table td {
    padding: 5px;
    background-color: white;
    color: black;
}

.set--route--page {
    width: 95vw;
    max-width: 500px;
    margin: 5px;
}

.set--route--page hr {
    border: 1px solid white;
}

.set--route--button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    padding: 10px;
    font-size: 20px;
    border-radius: 20px;
}

.set--route--button:hover {
    background-color: darkblue;
}

.set--route--button:active {
    background-color: #120846;
}

.welcome--challenge {
    border-radius: 20px;
    border: 5px solid white;
    padding: 30px;
}

.welcome--challenge * {
    margin: 0;
}