/* Sign in page */
.signin--page {
    text-align: center;
}

.signin--title {
    letter-spacing: 8px;
    font-size: 40px;
    font-weight: 200;
    margin-top: 50px;
}

.signin--form {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    margin-top: 100px;
}

.signin--input {
    padding: 5px;
    color: white;
    border: 2px solid white;
    border-radius: 8px;
    background-color: transparent;
}

.signin--input:focus {
    transition: 0.1s;
    outline: 1px solid white;
}

.input--limit--text {
    margin: 0;
    margin-top: -15px;
    text-align: right;
    font-weight: 300;
    color: lightgrey;
}

.signin--form * {
    font-family: "Roboto";
    font-size: 18px;
}

.signin--form input,
select,
button {
    min-height: 32px;
}

.signin--toggle {
    border: none;
    margin-left: 300px;
    min-width: 100px;
}

.signin--error--container {
    padding: 10px;
    border-radius: 20px;
    background-color: red;
}

.signin--error {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    font-size: large;
}

.signin--error--icon {
    font-weight: normal;
    font-size: larger;
}

.signin--eye--container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
}

.signin--eye--container * {
    margin: 0;
}

.signin--eye--icon {
    font-size: 24px;
    color: white;
}

.signin--eye--icon:hover {
    color: lightgray;
}

.signin--eye--icon:active {
    color: gray;
}

.signin--action--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 32px;
}

.signin--action--buttons {
    width: 100%;
    border: 2px white solid;
    background-color: transparent;
    color: white;
    border-radius: 7px;
    font-size: 22px;
    padding: 5px;
}

.signin--action--buttons:hover {
    transition: 0.2s;
    background-color: lightgrey;
}

.signin--action--buttons:active {
    background-color: grey;
}

.signin--submit {
    width: 100%;
    border: 2px white solid;
    background-color: white;
    color: black;
    border-radius: 8px;
    font-size: 22px;
    padding: 5px;
    letter-spacing: 6px;
    transition: 0.2s;
}

.signin--submit:hover {
    background-color: transparent;
    color: white;
}

.signin--submit:active {
    background-color: lightgray;
}

.signin--back {
    width: 100%;
    border: 2px white solid;
    background-color: transparent;
    color: white;
    border-radius: 8px;
    font-size: 22px;
    padding: 5px;
    letter-spacing: 6px;
    transition: 0.2s;
}

.signin--back:hover {
    background-color: white;
    color: black;
}

.signin--back:active {
    background-color: lightgray;
}

.signin--label {
    margin: 0;
    margin-bottom: -15px;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 15px;
    color: lightgray;
}

.signin--numbuttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signin--numbuttons>button {
    padding: 0px 10px;
    font-weight: 300;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 8px;
    transition: 0.2s;
}

.signin--numbuttons>button:hover {
    background-color: white;
    color: black;
}